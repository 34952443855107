<template>
  <div class="boxAh">
    <el-row class="dash-board">
      <div style="display: flex;position: absolute; top: 15px;left:723px;">
      <el-button type="primary" style="" class="cha" @click="queryBt()" v-permission="['Authenticate:select']" >查询</el-button>
      <el-button type="warning" style="" class="new" @click="reset">重置</el-button>
      <el-button type="primary" class="" @click="authenticates" v-permission="['Authenticate:batch']">批量鉴定</el-button>
      </div>
      <p class="studen" style="font-size: 12px">学员:</p>
      <p class="tai" style="font-size: 12px">状态:</p>
      <p class="iopcrn" style="font-size: 12px">班级:</p>
      <el-input class="in1" v-model="stuName" placeholder="输入学员姓名"></el-input> <!--学员-->
      <!-- <el-input class="in3" v-model="className" placeholder="输入班级"></el-input> -->
       <!-- 班级 -->
       <el-select style="width: 190px" class="in3" placeholder="请选择班级" v-model="className" filterable clearable>
          <el-option v-for="(item,index) in classData"
                    :key="index"
                    :label="item.className"
                    :value="item.classId"/>
        </el-select>
      <el-select class="in2" v-model="authStatusbox"><!--状态-->
        <div v-for="(i,k) in authStatusList" :key="k">
          <el-option :value="i.id" :label="i.value"></el-option>
        </div>
      </el-select>
    </el-row>
    <!-- 批量鉴定 -->
    <el-dialog title="班委鉴定" :visible.sync="toponce">
    <el-input type="textarea" rows="5" style="width: 500px" v-model="remark"></el-input>
    <div style="margin-top:10px">
      <el-button type="primary" @click="toponce = false">取消</el-button>
      <el-button type="primary" @click="noAuther(1)">确定</el-button>
      <el-button type="primary" @click="noAuthdudy()">单位鉴定</el-button>
    </div>
  </el-dialog>
    <el-dialog title="单位鉴定" :visible.sync="nicetwo">
      <el-input type="textarea" rows="5" style="width: 500px" v-model="remark"></el-input>
      <div style="margin-top:10px">
        <el-button type="primary" @click="nicetwo = false">取消</el-button>
        <el-button type="primary" @click="noAuthers(2)">确定</el-button>
      </div>
    </el-dialog>
    <el-row class="bg">
    <el-table
      :data="tableData"
      ref="multipleTable"
      style="width: 100%;" @selection-change="handleSelectionChangeTwo">
      <el-table-column type="selection" v-model="multipleSelection">
      </el-table-column>
      <el-table-column
        label="姓名"
        align="center"
        width="80">
        <template slot-scope="scope">
          <span>{{ scope.row.stuName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="性别"
        align="center"
        width="56">
        <template slot-scope="scope">
          <span v-if="scope.row.sex == 0">男</span>
          <span v-if="scope.row.sex == 1">女</span>
        </template>
      </el-table-column>
      <el-table-column
        label="班级"
        align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.className }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="单位名"
        align="center"
        width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.depName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系电话"
                       align="center"
                       width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.stuPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="职务"
        align="center"
        width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.stuDuty }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="班委鉴定状态"
        align="center"
        width="95 ">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.bwAuth == 0">未鉴定</el-tag>
          <el-tag v-if="scope.row.bwAuth == 1">已鉴定</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="单位鉴定状态"
        align="center"
        width="95">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.bzrAuth == 0">未鉴定</el-tag>
          <el-tag v-if="scope.row.bzrAuth == 1">已鉴定</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
         width="240">
        <template slot-scope="scope">
          <el-button type="success" size="mini" v-if="scope.row.bzrAuth == 1 || scope.row.bwAuth == 1" v-permission="['Authenticate:company']" @click="openChecked(scope.row)">鉴定查看</el-button>
          <el-button type="primary" size="mini" v-if="scope.row.bwAuth == 0" v-permission="['Authenticate:organization']" @click="open(scope.row)">班委鉴定</el-button>
          <!-- <el-button type="success" size="mini" v-if="scope.row.bwAuth == 1" v-permission="['Authenticate:organization']" @click="open(scope.row)">鉴定查看</el-button> -->
          <el-button type="primary" size="mini" v-if="scope.row.bzrAuth == 0" v-permission="['Authenticate:company']" @click="openTwo(scope.row)">单位鉴定</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <!-- 鉴定查看 -->
    <el-dialog :title="title" :visible.sync="open1">
      <el-form label-position="top">
        <el-form-item label="" v-if="bwAuth == 0"><span style="color: #1E8FC6;">班委未鉴定</span></el-form-item>
        <el-form-item label="班委鉴定意见"  v-if="bwAuth == 1">
          <el-input type="textarea" rows="2" style="width: 500px" v-model="remark" :disabled="true" v-if="bwAuth == 1"></el-input>
        </el-form-item>
        <el-form-item label="单位鉴定意见" v-if="bzrAuth == 1">
          <el-input type="textarea" rows="2" style="width: 500px" v-model="opinionText" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="" v-if="bzrAuth == 0"><span style="color: #1E8FC6;">单位未鉴定</span></el-form-item>
      </el-form>
      <div style="margin-top:10px">
        <el-button type="primary" @click="open1 = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 一条数据鉴定 -->
    <el-dialog title="班委鉴定" :visible.sync="handleEdits">
      <el-input type="textarea" rows="2" style="width: 500px" v-model="remark"></el-input>
      <div style="margin-top:10px">
        <el-button type="primary" @click="handleEdits = false">取消</el-button>
        <el-button type="primary" @click="noAuth()">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="单位意见" :visible.sync="handleEditTwo">
      <el-input type="textarea" rows="2" style="width: 500px" v-model="opinionText"></el-input>
      <div style="margin-top:10px">
        <el-button type="primary" @click="handleEditTwo = false">取消</el-button>
        <el-button type="primary" @click="noAuthTwo()">确定</el-button>
      </div>
    </el-dialog>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { AuthenticateList, appraisal, opinion, getAppraisalDetail } from '@/api/training.js'
  import { getAllClassByMoHu } from '@/api/user.js'
  import { insBworBzr } from '@/api/admin.js'
  export default {
    name: 'Leavefer',
    data () {
      return {
        classData: [], // 存储班级列表信息
        nicetwo: false,
        toponce: false,
        id: '',
        classId: '',
        stuId: '',
        bwAuth: null,
        bzrAuth: null,
        stuName: '',
        className: '',
        authStatusbox: '',
        authStatusList: [
          { id: 1, value: '未鉴定' },
          { id: 2, value: '已鉴定' }
        ],
        tableData: [],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        title: '单位意见',
        titlee: '班委鉴定',
        handleEdits: false,
        open1: false,
        // 班委鉴定意见
        remark: '',
        OperationType: '', // 操作类型 1班委鉴定 2单位意见
        handleEditTwo: false,
        // 单位鉴定意见
        opinionText: '',
        multipleSelection: []
      }
    },
    mounted () {
      this.queryBt()
      this.findClass()
    },
    methods: {
      // 获取班级列表
      findClass() {
        getAllClassByMoHu().then(response => {
          this.classData = response.data.data.rows
        })
      },
      queryBt() {
        const query = {
          stuName: this.stuName,
          classId: this.className,
          authStatus: this.authStatusbox,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        AuthenticateList(query).then(resp => {
          if (resp.data.code === '0') {
            this.tableData = resp.data.data.rows
            this.total = resp.data.data.total // 查询总条数
          }
        })
      },
      reset() { // 重置
        this.stuName = ''
        this.className = ''
        this.authStatusbox = ''
      },
      handleSizeChange(e) {
        console.log(e)
        this.pageSize = e
        this.queryBt()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.queryBt()
      },
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      // 点击班委鉴定
      open (row) {
        this.remark = null
        this.opinionText = null
        this.handleEdits = true
        this.id = row.id
        this.classId = row.classId
        this.stuId = row.stuId
      },
      // 点击单位意见
      openTwo(row) {
        this.remark = null
        this.opinionText = null
          this.handleEditTwo = true
          this.id = row.id
      },
      // 点击单位意见
      openChecked(row) {
        this.remark = null
        this.opinionText = null
        console.log(row)
        this.bwAuth = row.bwAuth
        this.bzrAuth = row.bzrAuth
        this.open1 = true
        this.title = '鉴定查看'
        getAppraisalDetail(row.id).then(res => {
          this.remark = res.data.data.bwOpinion
          this.opinionText = res.data.data.bzrOpinion
        })
      },
      noAuth() { // 一条数据鉴定---班委鉴定填写
        // this.OperationType = 1
        const query = {
          id: this.id,
          classId: this.classId,
          stuId: this.stuId,
          type: 1,
          bwOpinion: this.remark
        }
        appraisal(query).then(resp => {
          if (resp.data.code === '0') {
            this.$notify({
              title: '鉴定完成',
              type: 'success'
            })
            this.transit = false
            this.handleEdits = false
            this.queryBt()
          }
        })
      },
      noAuthTwo() { // 一条数据鉴定---单位鉴定
        // this.OperationType = 2
        const query = {
          id: this.id,
          classId: this.classId,
          stuId: this.stuId,
          type: 2,
          bzrOpinion: this.opinionText
        }
        opinion(query).then(resp => {
          if (resp.data.code === '0') {
            this.transitTow = false
            this.handleEditTwo = false
            this.$notify({
              title: '鉴定完成',
              type: 'success'
            })
            this.opinionText = ''
            this.queryBt()
          }
        })
      },
      authenticates() {
        console.log(this.multipleSelection)
        this.remark = null
        this.opinionText = null
        if (this.multipleSelection.length > 0) {
          this.toponce = true
        } else {
          this.$message.error('选择为空，请勾选需要批量鉴定的数据！')
        }
      },
      // 批量鉴定--班委鉴定
      noAuther(type) {
        this.multipleSelection.forEach(item => {
          item.type = type
          if (this.remark !== '') {
            item.bwOpinion = this.remark
          }
        })
        insBworBzr(this.multipleSelection).then(resp => {
          if (resp.data.code === '0') {
            this.toponce = false
            this.remark = ''
          }
        })
      },
       // 批量鉴定--单位鉴定
      noAuthers(type) {
        this.multipleSelection.forEach(item => {
          item.type = type
          if (this.remark !== '') {
            item.bzrOpinion = this.remark
          }
        })
        insBworBzr(this.multipleSelection).then(resp => {
          if (resp.data.code === '0') {
            this.toponce = false
            this.remark = ''
          }
          this.nicetwo = false
        })
      },
      noAuthdudy() {
        this.nicetwo = true
      },
      exportbt() { // 导出
        if (this.multipleSelection.length === 0) {
          this.$message({
            message: '请至少勾选一项，再进行操作',
            type: 'warning'
          })
        } else {
          this.speedupData()
        }
      },
      speedupData() {
        var arr = this.multipleSelection
        var multis = []
        for (var i = 0; i < arr.length; i++) {
          multis.push(arr[i].id)
          console.log(multis)
        }
        // opinion(multis).then(resp => {
        //    console.log(resp)
        //  })
      },
      handleSelectionChangeTwo(val) {
        this.multipleSelection = val
      }
    }
  }
</script>

<style scoped>
  .boxAh{margin-top: -10px}
.dash-board {display: flex; background-color: white; height: 60px; padding: 10px 0;}
.bg{margin-top: 15px; padding: 20px; background-color: white;}
  .inquire{
    position: absolute;
    top: 15px;
    left: 85%;
  }
  .reset{
    position: absolute;
    top: 15px;
    left: 90%;
  }
  .cha{
    /* position: absolute;
    top: 15px;
    left: 60%; */
  }
  .new{
    /* position: absolute;
    top:15px;
    left: 67%; */
  }
  .in1{
    width: 150px;
    position: absolute;
    top: 15px;
    left: 60px;
  }
  .studen{
    position: absolute;
    top: 10px;
    left: 20px;
  }
  .tai{
    position: absolute;
    top: 10px;
    left: 250px;
  }
  .in2{
    width: 150px;
    position: absolute;
    top: 15px;
    left: 290px;
  }
  .iopcrn{
    position: absolute;
    top: 10px;
    left: 480px;
  }
  .in3{
    width: 150px;
    position: absolute;
    top: 15px;
    left: 520px;
  }
  .nice{
    /* position: absolute;
    top: 15px;
    right: 10%; */
  }
</style>
